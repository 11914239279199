<template>
  <div>
    <!-- SPLIDE -->

    <div class="shadow-inner shadow-2xl z-20">
      <splide :slides="mainSliderSlides" :options="mainSliderOptions">
        <splide-slide
          v-for="(slide, i) in mainSliderSlides"
          :key="i"
          class="splide__slide w-full h-screen bg-home relative"
          style="background-size: cover"
          :style="{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url(${$api}/uploads/${slide.image})`,
          }"
        >
          <!--  -->
          <div class="text-center pt-20">
            <p
              style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)"
              class="uppercase text-white drop-shadow-sm pb-8 mt-40 tracking-loose w-full z-50"
            >
              {{ slide.smallCaption }}
            </p>
            <h1
              style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)"
              class="my-4 text-center mx-auto lg:text-3xl text-2xl lg:m-0 m-8 pb-2 drop-shadow-lg text-white font-bold leading-tight z-50"
            >
              {{ slide.caption }}
            </h1>
          </div>

          <div
            style="font-family: sans"
            class="absolute uppercase bottom-0 left-0 right-0 text-white text-xs text-center pb-8"
          >
            <svg
              class="text-center mx-auto opacity-100 animate-bounce"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>

            <span class="opacity-70"> Scroll Down </span>
          </div>
        </splide-slide>
      </splide>
    </div>

    <!-- SPLIDE // -->

    <!-- SPLASH -->
    <!-- <div class="bg-home relative mx-auto">
      <div
        style="font-family: sans;"
        class="absolute uppercase bottom-0 left-0 right-0 text-white text-xs text-center  pb-8"
      >
        <svg
          class="text-center mx-auto opacity-100 animate-bounce"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ffffff"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M6 9l6 6 6-6" />
        </svg>

        <span class="opacity-70"> Scroll Down </span>
      </div>
    </div> -->
    <!-- /SPLASH -->
    <!-- FAVORITE PROPERTIES -->
    <div class="container mx-auto bg-white mt-12 md:mt-18 mb-20">
      <div class="text-center text-highlight text-5xl mt-10 py-3 mb-6">
        Our Favorite Properties
      </div>

      <div class="max-w-6xl mx-auto p-4 md:p-0">
        <section class="py-2 pt-8">
          <LinksGrid :properties="featuredProperties"> </LinksGrid>
        </section>
      </div>
    </div>
    <!-- / FAVORITE PROPERTIES -->
    <!-- <div class="max-w-6xl mx-auto mb-20">
      <div class="text-center md:px-0 px-4 text-highlight text-4xl">
        Featured Special Offers
      </div>
      <div
        class="
          text-center
          px-8
          md:px-48
          py-4
          mb-8
          text-gray-700
          leading-8
          text-sm
        "
      ></div>
      <SpecialOfferCard
        v-for="(offer, i) in featuredPackages"
        :key="i"
        :specialOffer="offer"
      ></SpecialOfferCard>
    </div> -->
  </div>

  <a
    href="https://api.whatsapp.com/send?phone=+9607944493&text=Hi"
    class="float"
    target="_blank"
  >
    <!-- <svg
        class="my-float"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 24 24"
        fill="#25D366"
      >
        <path
          d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm.14 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"
        />
      </svg> -->

    <svg
      width="52"
      height="52"
      class="my-float"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z"
          fill="#BFC8D0"
        ></path>
        <path
          d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
          fill="url(#paint0_linear_87_7264)"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z"
          fill="white"
        ></path>
        <path
          d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z"
          fill="white"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_87_7264"
            x1="26.5"
            y1="7"
            x2="4"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5BD066"></stop>
            <stop offset="1" stop-color="#27B43E"></stop>
          </linearGradient>
        </defs>
      </g>
    </svg>
  </a>
</template>

<script>
import LinksGrid from "@/components/layout/LinksGrid";
// import SpecialOfferCard from "@/components/layout/SpecialOfferCard";
export default {
  name: "Home",
  data() {
    return {
      mainSliderOptions: {
        clones: 3,
        pagination: false,
        arrows: false,
        autoplay: true,
        interval: 4000,
      },
      featuredProperties: [],
      featuredPackages: [],
      specialOffers: [
        {
          name: "Summer Escape Balinese Special Massage",
          property: "Niyama Private Islands",
          bookBefore: "19 August 2022",
          travelBefore: "20 December 2022",
        },
        {
          name: "Summer Escape Balinese Special Massage",
          property: "Niyama Private Islands",
          bookBefore: "19 August 2022",
          travelBefore: "20 December 2022",
        },
        {
          name: "Summer Escape Balinese Special Massage",
          property: "Niyama Private Islands",
          bookBefore: "19 August 2022",
          travelBefore: "20 December 2022",
        },
        {
          name: "Summer Escape Balinese Special Massage",
          property: "Niyama Private Islands",
          bookBefore: "19 August 2022",
          travelBefore: "20 December 2022",
        },
        {
          name: "Summer Escape Balinese Special Massage",
          property: "Niyama Private Islands",
          bookBefore: "19 August 2022",
          travelBefore: "20 December 2022",
        },
      ],
    };
  },
  methods: {
    async getFeaturedProperties() {
      try {
        const res = await this.$axios.get(`${this.$api_v1}/featuredProperties`);

        if (res.status === 200) {
          if (res.data.featuredProperties.length > 7) {
            this.featuredProperties = res.data.featuredProperties
              .splice(0, 7)
              .map((p) => p.property);
          } else {
            this.featuredProperties = res.data.featuredProperties.map(
              (p) => p.property
            );
          }
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async getFeaturedPackages() {
      try {
        const res = await this.$axios.get(`${this.$api_v1}/featuredPackages`);

        if (res.status === 200) {
          this.featuredPackages = res.data.featuredPackages.map(
            (p) => p.package
          );
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  mounted() {
    this.getFeaturedProperties();
    this.getFeaturedPackages();
  },
  computed: {
    mainSliderSlides() {
      if (!this.featuredProperties || !this.featuredProperties.length)
        return [];

      let arr = this.featuredProperties.map((p) => {
        if (p.homeSliderImage && p.homeSliderImage.image) {
          return p.homeSliderImage;
        } else {
          return null;
        }
      });

      arr = arr.filter((item) => item);

      console.log(arr);
      return arr;
    },
  },
  components: {
    LinksGrid,
    // SpecialOfferCard,
  },
};
</script>

<style scoped>
.bg-home {
  height: 620px;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
  overflow: hidden;
  box-shadow: inset 0 0 0 2000px rgba(17, 17, 17, 0.4);
}

* {
  font-family: "Brown Regular";
  font-weight: 200;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Brown Regular"), url("/assets/Brown-Regular.ttf") format("ttf"),
    url("/assets/Brown-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Brown Regular";
  src: url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot");
  src: url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.svg#Brown Regular")
      format("svg");
}

.float {
  @apply md:bottom-20 bottom-20;
  position: fixed;
  width: 40px;
  height: 40px;
  right: 40px;
  color: white;
  text-align: center;

  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
</style>
